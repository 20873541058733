import { getEmdRefNumberFromPath } from "@/components/Utils/GetEmdRefNumber";
import type { AmplitudeEventNames, AmplitudeEventTypes } from "@/components/Views/Clients/Payments/types/amplitude.types";
import { defineStore } from "pinia";
import { UnionToIntersection } from "primevue/ts-helpers";
import { computed, ComputedRef, reactive } from "vue";

export const useLoginAnalytics = defineStore('loginAnalytics', () => {
    type LoginEvents = Extract<AmplitudeEventNames, `emd login - ${string}`>;
    type LoginPropsUnion = AmplitudeEventTypes[LoginEvents];
    type LoginPropsMerged = UnionToIntersection<LoginPropsUnion>;
    type LoginGetters = {
        [PropName in keyof LoginPropsMerged]: ComputedRef<LoginPropsMerged[PropName]> | null;
    };

    type KnownProps = typeof knownProps;
    const knownProps = {
        email: null,
        "file number": computed(() => getEmdRefNumberFromPath(true) ?? ''),
        phone: null,
        "portal type": computed(() => "client"),
        "prefilled": null,
    } satisfies LoginGetters

    type KnownPropsOnly<EventName extends AmplitudeEventNames> = {
        [PropName in keyof (AmplitudeEventTypes[EventName] | KnownProps) as KnownProps[PropName] extends null
            ? never
            : PropName]: KnownProps[PropName];
    };

    type KnownLoginProps = {
        [EventName in LoginEvents]: KnownPropsOnly<EventName>;
    };

    const eventToKnownProps = reactive<KnownLoginProps>({
        'emd login - open app': {
            "file number": knownProps['file number'],
            "portal type": knownProps['portal type'],
        },
        'emd login - email populated': {
            "file number": knownProps['file number'],
            "portal type": knownProps['portal type'],
        },
        'emd login - phone populated': {
            "file number": knownProps['file number'],
            "portal type": knownProps['portal type'],
        },
        'emd login - tos and pp accepted': {
            "file number": knownProps['file number'],
            "portal type": knownProps['portal type'],
        },
        'emd login - next step clicked': {
            "file number": knownProps['file number'],
            "portal type": knownProps['portal type'],
        },
    });

    type NeededProps<EventName extends LoginEvents> = Omit<
        AmplitudeEventTypes[EventName],
        keyof KnownLoginProps[EventName]
    >;

    /** Get a union of Event names that have no Properties that need to be passed in. */
    type EmptyNeededProps<EventName extends LoginEvents> =
        NeededProps<EventName> extends { [key: string]: never } ? EventName : never;

    const ampTrack = <EventName extends LoginEvents>(
        eventName: EventName,
        // the following type syntax is required to allow the param to be left off if no Properties are needed
        ...neededProps: EventName extends EmptyNeededProps<EventName> ? [] : [NeededProps<EventName>]
    ) => {
        const [passedInProps] = neededProps;
        const eventProps = Object.assign({ ...passedInProps }, eventToKnownProps[eventName]);
        window.amplitude.track(eventName, eventProps);
    };

    return { ampTrack };
});
