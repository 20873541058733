import LoadingDirective from '@/components/Directives/Loading';
import HttpClient from '@/components/Plugins/HttpClient';
import upload from '@/components/Plugins/Upload';
import { createHead } from '@unhead/vue';
import vClickOutside from 'click-outside-vue3';
import { createPinia, setActivePinia } from 'pinia';
import piniaPluginPersistedState from 'pinia-plugin-persistedstate';
import { createApp } from 'vue';
import * as SpaModule from './vue-app-spa';
// Font Awesome
import { library } from '@fortawesome/fontawesome-svg-core';
import {
    faArrowDownToLine,
    faArrowRight,
    faArrowsRotate,
    faArrowUpArrowDown,
    faBank,
    faCheck,
    faChevronDown,
    faChevronLeft,
    faChevronUp,
    faCircleDollar,
    faCircleInfo,
    faDollar,
    faEllipsis,
    faFileArrowDown,
    faLock,
    faPlus,
    faPrint,
    faQuestion,
    faSignature,
    faX,
    faXmark,
} from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

import {
    faAddressCard,
    faBuilding,
    faBuildingColumns,
    faCircleQuestion,
    faFolderArrowDown,
    faGraduationCap,
    faLockKeyhole,
    faMemoCircleInfo,
    faMessageDots,
    faPhone,
    faShieldKeyhole,
    faUser,
} from '@fortawesome/pro-regular-svg-icons';

import { faBolt, faBoltAuto, faFilePen, faListTimeline } from '@fortawesome/pro-light-svg-icons';
import Emittery from 'emittery';
import LoginFormComponent from './components/auth/LoginFormComponent.vue';
import EarlyDepositLogin from './components/Views/Clients/Payments/EarlyDepositLogin.vue';
import { BRIDGE_EVENT_EMITTER } from './shared/constants/event-emitter';
import { buildCompanyPortalRouter } from './spa/routes';

library.add([
    faAddressCard,
    faArrowDownToLine,
    faArrowRight,
    faArrowUpArrowDown,
    faArrowsRotate,
    faBank,
    faBolt,
    faBoltAuto,
    faBuilding,
    faBuildingColumns,
    faGraduationCap,
    faCheck,
    faChevronDown,
    faChevronLeft,
    faChevronUp,
    faCircleDollar,
    faCircleQuestion,
    faCircleInfo,
    faDollar,
    faEllipsis,
    faFileArrowDown,
    faFilePen,
    faFolderArrowDown,
    faListTimeline,
    faLock,
    faMemoCircleInfo,
    faMessageDots,
    faPhone,
    faPlus,
    faPrint,
    faQuestion,
    faShieldKeyhole,
    faSignature,
    faX,
    faXmark,
    faLockKeyhole,
    faUser,
]);

const modules = {
    'login-form': LoginFormComponent,
    'early-deposit-login': EarlyDepositLogin,
};

const vueExtensions = [vClickOutside, HttpClient, upload, createHead()];

/** Critical: pinia can only be created once to avoid messy multiple instances of the same named stores */
const pinia = createPinia();
pinia.use(piniaPluginPersistedState);
setActivePinia(pinia);

/** @param {JQuery.Selector} rootElementSelector */
export const initSpaModule = (rootElementSelector) => {
    SpaModule.mount(rootElementSelector);
};

/** @param {JQuery.Selector} rootElementSelector */
export const initVueSprinkle = (rootElementSelector) => {
    const app = createApp({});
    SpaModule.decorate(app, true);
    app.use(buildCompanyPortalRouter('/'));

    app.config.globalProperties.$log = console.log;
    app.config.unwrapInjectedRef = true;

    /** Event bridge to use for comunication between external world and vue world */
    const bridgeEventEmitter = new Emittery();
    app.provide(BRIDGE_EVENT_EMITTER, bridgeEventEmitter);

    for (const extension of vueExtensions) {
        app.use(extension);
    }
    app.mount(rootElementSelector);

    return { app, bridgeEventEmitter };
};

/**
 * @param {JQuery.Selector} elementId 
 * @param {{props: Parameters<typeof createApp>[1]}} createAppOptions 
 */
export const initVueComponent = async (elementId, { props } = {}) => {
    const module = (await import(`./vue-${elementId}.js`)).default;
    const app = createApp(module.component, props);

    app.config.globalProperties.$log = console.log;

    app.component('font-awesome-icon', FontAwesomeIcon);
    for (const extension of vueExtensions) {
        app.use(extension);
    }

    app.use(pinia);
    app.directive('loading', LoadingDirective);
    app.mount(module.element);

    return app;
};

/** @param {string} componentName */
export const loadComponent = (componentName) => {
    const component = modules[componentName];
    const app = createApp({});
    const tagNameInMarkup = componentName;
    app.component(tagNameInMarkup, component);
    app.component('font-awesome-icon', FontAwesomeIcon);

    app.config.globalProperties.$log = console.log;

    for (const extension of vueExtensions) {
        app.use(extension);
    }

    app.mount('#app');

    return app;
};
