/**
 * In the DB, an early deposit's ref number always begins with `EMD-`, but it
 * doesn't appear in the path with that prefix, so we have to add it back if needed.
 */
export const getEmdRefNumberFromPath = (withPrefix?: boolean): string | undefined => {
    const prefix = withPrefix ? 'EMD-' : '';
    const path = window.location.pathname.split('/');
    const isEarlyDepositRoute = path.indexOf('early-deposit');
    const ret = isEarlyDepositRoute === -1 ? undefined : path[isEarlyDepositRoute + 1];
    return ret?.length ? `${prefix}${ret}` : undefined; // convert empty string to undefined
}
