<template>
    <VerificationContent :indentation-level="0">
        <VerificationGrid
            :indentation-level="0"
            class="text-sm"
        >
            <CheckTextualResult
                label="File:"
                :value="{
                    state: CheckResultState.NotApplicable,
                    text: fileInformation.file,
                    stateText: '',
                    infoList: [],
                }"
            />
            <CheckTextualResult
                label="Email:"
                :value="
                    makeCheckResult({
                        state: CheckResultState.NotApplicable,
                        text: fileInformation.email,
                    })
                "
            />
            <CheckTextualResult
                label="Phone:"
                :value="
                    makeCheckResult({
                        state: CheckResultState.NotApplicable,
                        text: fileInformation.phone,
                    })
                "
            />
            <CheckTextualResult
                label="Date:"
                :value="
                    makeCheckResult({
                        state: CheckResultState.NotApplicable,
                        text: formattedDate,
                    })
                "
            />
            <Divider
                type="solid"
                class="col-span-5"
            />
            <CheckTextualResult
                label="Location:"
                :value="
                    makeCheckResult({
                        state: CheckResultState.NotApplicable,
                        text: fileInformation.location,
                    })
                "
            />
            <CheckTextualResult
                label="IP:"
                :value="
                    makeCheckResult({
                        state: CheckResultState.NotApplicable,
                        text: fileInformation.ip,
                    })
                "
            />
            <CheckTextualResult
                label="Authentication:"
                :value="
                    makeCheckResult({
                        state: CheckResultState.NotApplicable,
                        text: fileInformation.authentication,
                    })
                "
            />
        </VerificationGrid>
    </VerificationContent>
</template>

<script lang="ts" setup>
import type { BankAccountReportResult } from '@/api/interfaces/bank-account-verification.api';
import { CheckResultState } from '@/api/interfaces/identity-verification-report.api';
import { makeCheckResult } from '@/components/Components/BankAccountVerification/report-check-result-mapping';
import { formatDate } from '@/shared/utils/format-date';
import Divider from 'primevue/divider';
import { computed } from 'vue';
import CheckTextualResult from '../../IdentityVerificationReport/common/CheckTextualResult.vue';
import VerificationContent from '../../IdentityVerificationReport/common/VerificationContent.vue';
import VerificationGrid from '../../IdentityVerificationReport/common/VerificationGrid.vue';

const props = defineProps<{ fileInformation: BankAccountReportResult['file_information'] }>();

const formattedDate = computed(() => formatDate(props.fileInformation.date, 'LLL', ''));
</script>
