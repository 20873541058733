import { useClientApi, useCompanyApi } from '@/api/client/api-client';
import {
    type BankAccountCreationRequest,
    BankAccountCreationResponse,
    BankAccountNumberSource,
    type BankAccountReportResult,
    BankAccountVerificationRequest,
    BankAccountVerificationResult,
    BavFormAccountHolder,
    BAVFormData,
    BAVRequestAccountHolder,
    type RoutingNumberVerificationResult,
} from '@/api/interfaces/bank-account-verification.api';
import type { AccountType } from '@/components/Views/Clients/WireInstructions/types';
import { useJWTService } from '@/services/jwt.service';
import * as Yup from 'yup';

const stripAllButNumbers = (value: string) => value.replaceAll(/\D/g, '');

export function useBankAccountVerificationService() {
    const companyApi = useCompanyApi();
    const clientApi = useClientApi();
    const jwtService = useJWTService();
    async function validateRoutingNumber(routingNumber: string): Promise<RoutingNumberVerificationResult> {
        const validateRoutingNumberRoute = '/client/manualBAVValidateRoutingNumber';
        try {
            const { data, error } = await companyApi(validateRoutingNumberRoute)
                .post({ wire_routing_number: routingNumber })
                .json<RoutingNumberVerificationResult>();
            if (error.value || !data.value) {
                return Promise.reject(error.value);
            }
            return data.value;
        } catch (error) {
            return Promise.reject(error);
        }
    }

    async function verifyBankAccount(
        clientFileId: string,
        bavRequest: BankAccountVerificationRequest,
    ): Promise<BankAccountVerificationResult> {
        // This route is protected by a JWT token, so we need to get a JWT token to access it.
        const jwtClientApi = await jwtService.createWrappedClient('client');
        const verificationRoute = `/api/bav/submitBav/${clientFileId}`;
        try {
            const { data, error } = await jwtClientApi(verificationRoute).post(bavRequest).json<BankAccountVerificationResult>();
            if (error.value || !data.value || !data.value['bav-uuid']) {
                // if we have an error or are missing the bav-uuid, we will throw an error.
                throw error.value || new Error('Missing bav-uuid in response');
            }
            return data.value;
        } catch (error) {
            return Promise.reject(error);
        }
    }

    async function createManualBankAccount(
        request: BankAccountCreationRequest,
    ): Promise<BankAccountCreationResponse> {
        // This route-name is misleading, as it is actually used to create a manual bank account, needed for the BAV process.
        const createManualBankAccountRoute = '/client/manualBAV';
        try {
            const { data, error } = await clientApi(createManualBankAccountRoute)
                .post(request)
                .json<BankAccountCreationResponse>();
            if (error.value || !data.value) {
                throw new Error(error.value);
            }
            return data.value;
        } catch (error) {
            return Promise.reject(error);
        }
    }

    function buildBankAccountCreationRequestFromFormData(formData: BAVFormData, fileId: string): BankAccountCreationRequest {
        const { owner_name, owner_address } = getOwnerInfo(formData);
        return {
            owner_name,
            owner_address,
            bank_name: formData.bank_name!,
            account_number: formData.account_number,
            account_number_confirmation: formData.account_number_confirmation,
            routing_number: formData.wire_routing_number,
            file_id: fileId,
            notes: formData.additional_information,
            verification_service_down: false,
        };
    }

    function buildBankAccountVerificationRequestFromFormData(formData: BAVFormData): BankAccountVerificationRequest {
        const triggeringClient = formData.account_holders.find(holder => holder.ssn);
        // find the first account holder with an SSN. that is our triggering client that we will compare the BAV results against
        const account_holders: BAVRequestAccountHolder[] = formData.account_holders.map(holder => {
            const strippedSSN = holder.ssn ? stripAllButNumbers(holder.ssn) : '';
            const accountHolder: BAVRequestAccountHolder = {
                first_name: holder.first_name,
                last_name: holder.last_name,
                first_name_source: 'manual',
                last_name_source: 'manual',
                triggering_client: triggeringClient === holder,
                ssn: strippedSSN,
                ssn_source: 'manual',
            };
            return accountHolder;
        });
        // if there is no SSN, we will use the first account holder as the triggering client
        if (!triggeringClient) {
            account_holders[0].triggering_client = true;
        }
        const request: BankAccountVerificationRequest = {
            routing_number: formData.wire_routing_number,
            account_number: formData.account_number,
            routing_number_source: 'manual',
            account_number_source: 'manual',
            additional_information: formData.additional_information,
            account_holders,
        };
        if (formData.bank_account_type === 'business') {
            const strippedEIN = formData.ein ? stripAllButNumbers(formData.ein) : '';
            request.business_name = formData.business_name;
            request.ein = strippedEIN;
        }
        return request;
    }

    function getOwnerInfo(request: BAVFormData): { owner_name: string; owner_address: string } {
        let owner: BavFormAccountHolder;
        if (!request.account_holders) {
            throw new Error('No account holders found');
        }
        if (request.bank_account_type === 'business') {
            owner = request.account_holders[0];
        } else {
            owner = request.account_holders.find(holder => holder.ssn) as BavFormAccountHolder;
        }
        if (!owner) {
            throw new Error('No owner found with a valid SSN');
        }
        return {
            owner_name: `${owner.first_name} ${owner.last_name}`,
            owner_address: request.account_holder_address,
        };
    }

    const buildManualBankAccountValidationSchema = (accountType: AccountType) => {
        let BankFormSchema = Yup.object().shape({
            account_holder_address: Yup.string()
                .required('Account Holder address is required')
                .min(1, 'Please enter a valid address'),
            account_number: Yup.string()
                .min(4, 'Your account number must be at least 4 digits')
                .required('Account number is required'),
            account_number_confirmation: Yup.string()
                .min(4, 'Please confirm your account number')
                .required('Account confirmation is required')
                .oneOf([Yup.ref('account_number')], 'Account number confirmation does not match account number'),
            wire_routing_number: Yup.string()
                .required('Wire routing number is required')
                .length(9, 'Please enter a valid wire routing number'),
            additional_information: Yup.string().nullable(),
            bank_account_type: Yup.string(),
        });

        if (accountType === 'business') {
            BankFormSchema = BankFormSchema.shape({
                business_name: Yup.string().min(1, 'Please enter a valid business name').required('Business name is required'),
                ein: Yup.string()
                    .required('EIN number is required')
                    .matches(/^\d{2}-\d{7}$/, 'Please enter a valid EIN number'),
                account_holders: Yup.array()
                    .of(
                        Yup.object().shape({
                            first_name: Yup.string().min(1, 'Please enter a first name'),
                            last_name: Yup.string().min(1, 'Please enter a last name'),
                        }),
                    )
                    .min(1, 'Please enter at least one account holder'),
            });
        } else {
            BankFormSchema = BankFormSchema.shape({
                account_holders: Yup.array()
                    .of(
                        Yup.object().shape({
                            first_name: Yup.string().min(1, 'Please enter a first name'),
                            last_name: Yup.string().min(1, 'Please enter a last name'),
                            ssn: Yup.string().nullable(),
                        }),
                    )
                    .test('at-least-one-ssn', 'At least one account holder must have a valid SSN', account_holders => {
                        const atLeastOne =
                            account_holders?.some(holder => holder.ssn && /^\d{3}-\d{2}-\d{4}$/.test(holder.ssn)) || false;
                        return atLeastOne;
                    })
                    .min(1, 'Please enter at least one account holder'),
            });
        }
        return BankFormSchema;
    };

    async function getReportById(bankAccountId: number): Promise<BankAccountReportResult | null> {
        try {
            // This is a JWT protected route, so we need to get a JWT token to access it.
            const jwtCompanyApi = await jwtService.createWrappedClient('company');
            const { data, error } = await jwtCompanyApi(`/api/bav/report/${bankAccountId}`).get().json<BankAccountReportResult>();
            if (error.value || !data.value || Object.keys(data.value).length === 1 && Object.keys(data.value).includes('message')) {
                window.Bugsnag?.notify('Error getting BAV report data');
                throw new Error('Error fetching bank account verification report');
            }
            return data.value;

        } catch (error) {
            return Promise.reject(error);
        }
    }

    function getReportDownloadUrl(reportId: number): string {
        return `/api/bav/report/${reportId}/download`;
    }

    function formatBankingSource(source?: BankAccountNumberSource): string {
        switch (source) {
            case BankAccountNumberSource.manual:
                return 'User submitted';
            case BankAccountNumberSource.automatic:
                return 'Verified';
            case BankAccountNumberSource.client:
                return 'Client submitted';
            default:
                return '';
        }
    }

    return {
        validateRoutingNumber,
        verifyBankAccount,
        buildBankAccountCreationRequestFromFormData,
        buildBankAccountVerificationRequestFromFormData,
        buildManualBankAccountValidationSchema,
        formatBankingSource,
        getReportById,
        getReportDownloadUrl,
        createManualBankAccount,
        getOwnerInfo,
    };
}
