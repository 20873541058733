<template>
    <Dialog
        v-model:visible="dialogOpen"
        modal
        position="center"
        :draggable="false"
        :dismissableMask="true"
        :closable="true"
        :close-on-escape="true"
        :showHeader="false"
        :block-scroll="false"
        :pt="{
            root: 'p-0 h-[98vh] xs:w-full max-w-7xl rounded-t-lg overflow-hidden',
            content: 'px-0 overflow-hidden ',
            header: 'h-0 p-1',
        }"
        :close-icon="' '"
        :pt-options="{ mergeProps: true }"
    >
        <template #closeicon></template>
        <BankAccountVerificationReport
            v-if="ready"
            :reportResult
            :downloadUrl
            :hasError="errorWithReport"
            @close="closeModal"
        />
    </Dialog>
</template>

<script lang="ts" setup>
import type { BankAccountReportResult } from '@/api/interfaces/bank-account-verification.api';
import { useBankAccountVerificationService } from '@/services/bank-account-verification.service';
import { BRIDGE_EVENT_EMITTER, BRIDGE_EVENTS } from '@/shared/constants/event-emitter';
import type Emittery from 'emittery';
import Dialog from 'primevue/dialog';
import { inject, ref } from 'vue';
import BankAccountVerificationReport from './BankAccountVerificationReport.vue';

/** This is a wrapper for the BankAccountVerificationReport component. It exists as an entry point
 * while we are using a vue sprinkle to instantiate a vue app in the old jquery code.
 * The handling of events from external code should live here to keep components down unaware of that.
 * It should be kept thin and not contain business logic and should be removed once
 * we have a proper SPA replacing the current file management page or equivalent
 */

const currentReportId = ref<number>();
const ready = ref(false);
const downloadUrl = ref<string>();
const dialogOpen = ref(false);
const bridgeEventEmitter = inject<Emittery>(BRIDGE_EVENT_EMITTER);

bridgeEventEmitter?.on(BRIDGE_EVENTS.BAV.CHANGE_CURRENT_BAV_REPORT, async ({ bankAccountId }: { bankAccountId: number }) => {
    currentReportId.value = undefined;
    if (!bankAccountId) {
        ready.value = false;
        errorWithReport.value = false;
        dialogOpen.value = false;
        currentReportId.value = undefined;
        downloadUrl.value = undefined;
        return;
    }
    currentReportId.value = bankAccountId;
    getReportByBankAccountId(bankAccountId);
    dialogOpen.value = true;
});

const bavService = useBankAccountVerificationService();
const reportResult = ref<BankAccountReportResult>();
const errorWithReport = ref(false);

async function getReportByBankAccountId(bankAccountId: number) {
    try {
        const data = await bavService.getReportById(bankAccountId);
        if (data) {
            errorWithReport.value = false;
            reportResult.value = data;
            downloadUrl.value = bavService.getReportDownloadUrl(bankAccountId);
        }
    } catch (error) {
        errorWithReport.value = true;
    } finally {
        ready.value = true;
    }
}

function closeModal() {
    dialogOpen.value = false;
}
</script>
