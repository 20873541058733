import { MatchResultState, type BAVReportMatchCheckResult } from '@/api/interfaces/bank-account-verification.api';
import type { CheckResult } from '@/api/interfaces/identity-verification-report.api';
import { CheckResultState } from '@/api/interfaces/identity-verification-report.api';

export function makeCheckResult(overrides: Partial<CheckResult> = {}): CheckResult {
    return {
        state: CheckResultState.NotApplicable,
        text: '',
        stateText: '',
        infoList: [],
        ...overrides,
    };
}

export function mapBavMatchToCheckResult(check: BAVReportMatchCheckResult): CheckResult {
    const matchCorrespondence = {
        [MatchResultState.match]: CheckResultState.Passed,
        [MatchResultState.closeMatch]: CheckResultState.CautiousFailed,
        [MatchResultState.noMatch]: CheckResultState.Failed,
        [MatchResultState.unknown]: CheckResultState.NotApplicable,
    } as const;

    const matchText = {
        [MatchResultState.match]: 'Match',
        [MatchResultState.closeMatch]: 'Close match',
        [MatchResultState.noMatch]: 'Unverified',
        [MatchResultState.unknown]: 'Unknown',
    } as const;

    return {
        state: matchCorrespondence[check.match],
        text: check.value,
        stateText: matchText[check.match],
        infoList: [],
    };
}
