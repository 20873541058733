import { useClientApi, useCompanyApi } from '@/api/client/api-client';

const TokenTTL = 60 * 60 * 1000; // 1 hour

type JWT = string;
type PortalType = 'client' | 'company';

type APIClient = {
    fetchClient: ReturnType<typeof useClientApi> | ReturnType<typeof useCompanyApi>;
    fetchClientFallback: typeof useClientApi | typeof useCompanyApi;
    endpoint: string;
};

export function useJWTService() {
    const clientApi = useClientApi();
    const companyApi = useCompanyApi();
    // @TODO: add employee JWT support
    // const employeeApi = useEmployeeApi();
    let cachedToken: JWT | null = null;
    let tokenTimestamp: number | null = null;
    const portalTypes: Record<PortalType, APIClient> = {
        client: {
            fetchClient: clientApi,
            fetchClientFallback: useClientApi,
            endpoint: '/jwt/client',
        },
        company: {
            fetchClient: companyApi,
            fetchClientFallback: useCompanyApi,
            endpoint: '/jwt/user',
        },
        // @TODO: add employee JWT support
    };

    const getJWTForApp = async (client:PortalType): Promise<JWT> => {
        const tokenExpired = tokenTimestamp && Date.now() - tokenTimestamp > TokenTTL;
        if (!cachedToken || tokenExpired) {
            try {
                const { fetchClient, endpoint } = portalTypes[client];
                const { data, error } = await fetchClient(endpoint).json<{ token: JWT }>();
                if (error.value) {
                    return Promise.reject(error.value);
                }
                tokenTimestamp = Date.now();
                cachedToken = data.value!.token;

            } catch (error) {
                console.error('Error fetching JWT:', error);
                return Promise.reject(error);
            }
        }
        return cachedToken!;
    };
    const getClientJWT = async (): Promise<JWT> => {
        return getJWTForApp('client');
    }
    const getCompanyJWT = async (): Promise<JWT> => {
        return getJWTForApp('company');
    }
    const createWrappedClient = async (client: PortalType): Promise<ReturnType<typeof useClientApi>> => {
        try {
            const token = await getJWTForApp(client);
            // now that we have the token, we can create a new fetch client, with the token in the headers
            const jwtFetchClient = portalTypes[client].fetchClientFallback('', { Authorization: `Bearer ${token}` });
            return jwtFetchClient;
        } catch (error) {
            // if we have any error, we should return the original fetch client without the token.
            // calls to this client will fail, but we can handle that in the calling code
            console.error('Error creating wrapped client:', error);
            return portalTypes[client].fetchClient;
        }
    }

    // @TODO: add employee JWT support
    return {
        getClientJWT,
        getCompanyJWT,
        // @TODO: add employee JWT support
        createWrappedClient,
    };
};