<template>
    <VerificationContent :indentation-level="0">
        <VerificationGrid
            :indentation-level="0"
            class="text-sm"
        >
            <template v-if="accountHolderDetails.ein">
                <CheckTextualResult
                    label="Business Name:"
                    v-if="accountHolderDetails.business_name"
                    :colorful-state="false"
                    :inlineTextContent="true"
                    with-state-icon
                    :value="
                        makeCheckResult({
                            state: CheckResultState.NotApplicable,
                            text: accountHolderDetails.business_name || '',
                        })
                    "
                >
                    <template #post-label-row>
                        <div class="text-xxs">Source: User submitted</div>
                    </template>
                </CheckTextualResult>
                <CheckTextualResult
                    label="EIN Number:"
                    v-if="accountHolderDetails.ein"
                    :colorful-state="false"
                    :inlineTextContent="true"
                    :value="
                        makeCheckResult({
                            state: CheckResultState.NotApplicable,
                            text: accountHolderDetails.ein || '',
                        })
                    "
                >
                    <template #post-label-row>
                        <div class="text-xxs">Source: User submitted</div>
                    </template>
                </CheckTextualResult>
                <CheckTextualResult
                    label="Bank Account Mailing Address:"
                    v-if="accountHolderDetails.bank_nailing_address"
                    :colorful-state="true"
                    :inlineTextContent="true"
                    with-state-icon
                    :value="
                        makeCheckResult({
                            state: CheckResultState.NotApplicable,
                            text: accountHolderDetails.bank_nailing_address,
                        })
                    "
                >
                    <template #post-label-row>
                        <div class="text-xxs">Source: User submitted</div>
                    </template>
                </CheckTextualResult>
                <CheckTextualResult
                    label="Zip code:"
                    :colorful-state="true"
                    :inlineTextContent="true"
                    with-state-icon
                    :value="mapBavMatchToCheckResult(accountHolderDetails.zip_code)"
                >
                    <template #post-label-row>
                        <div class="text-xxs">Source: User submitted</div>
                    </template>
                </CheckTextualResult>
                <Divider class="col-span-5" />
            </template>
            <template
                v-for="(accountHolder, k) in accountHolderWithKey.account_holders"
                :key="accountHolder.key"
            >
                <div class="col-span-5 font-bold text-xxs">Account holder {{ k + 1 }}</div>
                <CheckTextualResult
                    label="First Name:"
                    :colorful-state="true"
                    :inlineTextContent="true"
                    with-state-icon
                    :value="mapBavMatchToCheckResult(accountHolder.first_name)"
                >
                    <template #post-label-row>
                        <div class="text-xxs">Source: User submitted</div>
                    </template>
                </CheckTextualResult>
                <CheckTextualResult
                    label="Last Name:"
                    :colorful-state="true"
                    :inlineTextContent="true"
                    with-state-icon
                    :value="mapBavMatchToCheckResult(accountHolder.last_name)"
                >
                    <template #post-label-row>
                        <div class="text-xxs">Source: User submitted</div>
                    </template>
                </CheckTextualResult>
                <Divider
                    class="col-span-5"
                    v-if="accountHolderWithKey.account_holders[k + 1]"
                />
            </template>
        </VerificationGrid>
    </VerificationContent>
</template>
<script lang="ts" setup>
import type { BankAccountReportResult } from '@/api/interfaces/bank-account-verification.api';
import { CheckResultState } from '@/api/interfaces/identity-verification-report.api';
import {
  makeCheckResult,
  mapBavMatchToCheckResult,
} from '@/components/Components/BankAccountVerification/report-check-result-mapping';
import Divider from 'primevue/divider';
import { computed } from 'vue';
import CheckTextualResult from '../../IdentityVerificationReport/common/CheckTextualResult.vue';
import VerificationContent from '../../IdentityVerificationReport/common/VerificationContent.vue';
import VerificationGrid from '../../IdentityVerificationReport/common/VerificationGrid.vue';

const props = defineProps<{
    accountHolderDetails: BankAccountReportResult['account_holders'];
}>();

const accountHolderWithKey = computed(() => {
    return {
        ...props.accountHolderDetails,
        account_holders: props.accountHolderDetails.account_holders.map(accountHolder => ({
            ...accountHolder,
            key: Math.floor(Math.random() * Math.pow(props.accountHolderDetails.account_holders.length, 10)),
        })),
    };
});
</script>
