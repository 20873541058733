<template>
    <VerificationContent :indentation-level="0">
        <VerificationGrid
            :indentation-level="0"
            class="text-sm"
        >
            <CheckTextualResult
                label="Institution:"
                :value="
                    makeCheckResult({
                        state: CheckResultState.NotApplicable,
                        text: bankingInformation.institution,
                    })
                "
            />
            <CheckTextualResult
                label="Bank Address:"
                :value="
                    makeCheckResult({
                        state: CheckResultState.NotApplicable,
                        text: bankingInformation.bank_address,
                    })
                "
            />
            <CheckTextualResult
                label="Subtype:"
                :value="
                    makeCheckResult({
                        state: CheckResultState.NotApplicable,
                        text: bankingInformation.subtype,
                    })
                "
            />
            <Divider class="col-span-5 py-0" />
            <div class="text-xs">
                <div>
                    Account #: <strong>{{ bankingInformation.account_number?.value }}</strong>
                </div>
                <div class="text-xxs">
                    Source: {{ bavService.formatBankingSource(bankingInformation.account_number?.source) }}
                </div>
            </div>
            <div class="text-xs">
                <div>
                    Wire routing #: <strong>{{ bankingInformation.wire_routing_number?.value }}</strong>
                </div>
                <div class="text-xxs">
                    Source: {{ bavService.formatBankingSource(bankingInformation.wire_routing_number?.source) }}
                </div>
            </div>
        </VerificationGrid>
    </VerificationContent>
</template>

<script lang="ts" setup>
import type { BankAccountReportResult } from '@/api/interfaces/bank-account-verification.api';
import { CheckResultState } from '@/api/interfaces/identity-verification-report.api';
import { makeCheckResult } from '@/components/Components/BankAccountVerification/report-check-result-mapping';
import { useBankAccountVerificationService } from '@/services/bank-account-verification.service';
import Divider from 'primevue/divider';
import CheckTextualResult from '../../IdentityVerificationReport/common/CheckTextualResult.vue';
import VerificationContent from '../../IdentityVerificationReport/common/VerificationContent.vue';
import VerificationGrid from '../../IdentityVerificationReport/common/VerificationGrid.vue';

defineProps<{ bankingInformation: BankAccountReportResult['banking_information'] }>();

const bavService = useBankAccountVerificationService();
</script>
