import { useEmployeeApi } from '@/api/client/api-client';
import { ValidateRoutingNumberResult } from '@/api/interfaces/banking.api';
import { RoutingNumberValidation } from '@/interfaces/banking';

export function useBankingService() {
    const employeeApi = useEmployeeApi();

    async function getRoutingNumberBank(routingNumber: string): Promise<RoutingNumberValidation> {
        const { data, error } = await employeeApi('/api/manualBAVValidateRoutingNumber')
            .post({ wire_routing_number: routingNumber })
            .json<ValidateRoutingNumberResult>();
        if (error.value) {
            console.error('Error trying to check routing number validity', error.value);
            return {
                isValid: false,
            };
        }

        return {
            isValid: !!data?.value?.bank_name,
            bankName: data?.value?.bank_name,
        };
    }
    return {
        getRoutingNumberBank,
    };
}
