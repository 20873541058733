
export interface BankAccountReportResult {
    file_information: FileInformation;
    banking_information: BankingInformation;
    account_status: AccountStatus;
    account_holders: AccountHolders;
};

export interface AccountHolders {
    business_name: string;
    bank_nailing_address: string;
    ein?: string;
    zip_code: BAVReportMatchCheckResult;
    account_holders: BavReportAccountHolder[];
};

export interface BavReportAccountHolder {
    first_name: BAVReportMatchCheckResult;
    last_name: BAVReportMatchCheckResult;
    ssn: BAVReportMatchCheckResult;
};

export interface BavFormAccountHolder {
    first_name: string;
    last_name: string;
    ssn?: string;
};

export interface BavFormAccountHolder {
    first_name: string;
    last_name: string;
    ssn?: string;
}

export enum AccountHolderCheckSource {
    idv = 'idv',
};

export enum SourceType {
    client = 'client',
    idv = 'idv',
    title = 'title',
    ip = 'ip',
};

export enum MatchResultState {
    match = 'Y',
    noMatch = 'N',
    closeMatch = 'C',
    unknown = 'U', // TODO double check this value against BE implementation. "U" was present in the json mock but could be absent from real BE response
};

export interface BAVReportMatchCheckResult {
    value: string;
    source: SourceType;
    match: MatchResultState;
};

export enum AccountStatusStanding {
    valid = 'valid',
    unverified = 'unverified',
};

export interface AccountStatus {
    standing: AccountStatusStanding;
    open_date: string;
    last_update?: string;
};

export interface BankingInformation {
    institution: string;
    bank_address: string;
    subtype: string;
    account_number: BankAccountNumber;
    wire_routing_number: BankAccountNumber;
};

export enum BankAccountNumberSource {
    manual = 'manual',
    automatic = 'automatic',
    client = 'client',
};
export interface BankAccountNumber {
    value: string;
    source: BankAccountNumberSource;
};

export interface FileInformation {
    file: string;
    email: string;
    phone: string;
    date: string;
    location: string;
    ip: string;
    authentication: string;
};

type ManualSource = 'manual';

export interface BAVRequestAccountHolder {
    first_name: string;
    last_name: string;
    ssn: string;
    first_name_source: ManualSource;
    last_name_source: ManualSource;
    triggering_client: boolean;
    ssn_source: ManualSource;
};

export type AccountTypes = 'business' | 'personal' | null;

export interface BankAccountVerificationRequest {
    routing_number: string;
    account_number: string;
    routing_number_source: ManualSource;
    account_number_source: ManualSource;
    additional_information: string;
    business_name?: string;
    ein?: string;
    account_holders: BAVRequestAccountHolder[];
};

export interface BankAccountCreationRequest {
    owner_name: string;
    owner_address: string;
    bank_name: string;
    account_number: string;
    account_number_confirmation: string;
    routing_number: string;
    file_id: string;
    notes: string;
    verification_service_down: boolean;
};

export type BankAccountCreationResponse = string;


export type BankAccountVerificationResult = {
    status: 'success' | 'error';
    'bav-uuid'?: string;
};

export type BAVFormData = {
    wire_routing_number: string;
    account_number: string;
    account_number_confirmation: string;
    account_holder_address: string;
    account_type: string;
    bank_name: string | null;
    bank_account_type: AccountTypes; 
    additional_information: string;
    valid_route_number: boolean;
    business_name?: string;
    ein?: string;
    account_holders: BavFormAccountHolder[];
};

export type RoutingNumberVerificationResult = {
    valid: boolean;
    verificationServiceDown: false;
    bank_name: string;
    bank_address: {
        line1?: string;
        line2?: string;
        locality?: string;
        region?: string;
        postal_code?: string;
        country?: string;
    };
};

export type RoutingNumberVerificationResponse = RoutingNumberVerificationResult;
