import { csrfToken } from '@/components/Utils/Helper';
import { createFetch } from '@vueuse/core';

type HeadersInit = Record<string, string>;
function buildFetch() {
    return (url?: string, headers:  HeadersInit = { 'X-CSRF-TOKEN': csrfToken?.value }) =>
        createFetch({
            baseUrl: url,
            fetchOptions: {
                headers
            },
        });
};

export const useEmployeeApi = buildFetch();
export const useCompanyApi = buildFetch();
export const useClientApi = buildFetch();
